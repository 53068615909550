<template> 
   <v-card class="side-menu d-flex flex-column align-stretch" style="">
        <div class="d-flex justify-end flex-grow-0">
            <uv-button  @click="closeSideMenu" icon title="Close XBRL Menu">
                <v-icon>mdi-close-circle-outline</v-icon>
            </uv-button>
        </div>
        <slot></slot>
    </v-card>
    
</template>

<script>

export default {
    name: "PageSideMenu",
    methods: {
        closeSideMenu() {
            this.$store.dispatch('xbrlData/closeSideMenu');
        },
              
    },
}
</script>

<style lang="scss" scoped>
   .side-menu {
        min-height: 70vh;
        max-height: 77vh;
        width: 65vw;
        background-color: white;
    }
</style>
